import React from "react";
import { useState, useEffect } from "react";
import Markdown from 'react-markdown';
import TermsAndConditions from '@assets/docs/terms.md';

const Terms = () => {

  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(TermsAndConditions)
      .then(response => response.text())
      .then(text => setContent(text));
  }, []);

  return (
    <main>
      <section className="terms">
        <Markdown>{content}</Markdown>
      </section>
    </main>
  );
};

export { Terms };
