import React from "react";
import { NavLink } from "react-router-dom";
import {
  Home,
  EmojiFunnyCircle,
  SquareArrowRightUp,
  InboxIn,
  StarCircle
} from "@app/components/Icons";

import helloInvent from "@assets/images/helloInvent.png";

const Footer = () => (
  <footer className="footer">
    <div className="footer-company">
      <a href="//helloinvent.com" className="footer-logo">
        <img
          src={helloInvent}
          width="142.31"
          height={54.95}
          alt="Hello Invent Logo"
        />
      </a>
      <div className="footer-copy">
        <b>Hello Invent © 2024</b>
        <br />
        All rights reserved
      </div>
    </div>
    <div className="footerSections">
      <ul className="footerSection">
        <li className="footerSection-header">
          {/* <Home stroke="white" width={20} height={20} className="_mr5" /> */}
          Main
        </li>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <a href="/docs">Docs</a>
        </li>
        <li>
          <NavLink to="/dashboard">Dashboard</NavLink>
        </li>
      </ul>

      <ul className="footerSection">
        <li className="footerSection-header">
          {/* <EmojiFunnyCircle
            color="white"
            width={20}
            height={20}
            className="_mr5"
          /> */}
          Docs
        </li>
        <li>
          <a href="/docs/intro">Intro</a>
        </li>
        <li>
          <a href="/docs/quickstart">QuickStart</a>
        </li>
        <li>
          <a href="/docs/category/tutorial---basics">Tutorial - Basics</a>
        </li>
        <li>
          <a href="/docs/category/tutorial---extras">Tutorial - Extra</a>
        </li>
      </ul>
      <ul className="footerSection">
        <li className="footerSection-header">
          {/* <StarCircle stroke="white" width={20} height={20} className="_mr5" /> */}
          More
        </li>
        <li className="_flex">
          <NavLink to="https://www.npmjs.com/package/shipthis" target="_blank">
            NPM package
            <SquareArrowRightUp
              width={18}
              height={18}
              className="footer-externalLink"
            />
          </NavLink>
        </li>
        <li>
          <NavLink to="/privacy">Privacy</NavLink>
        </li>
        <li>
          <NavLink to="/terms">Terms and Conditions</NavLink>
        </li>
      </ul>
    </div>
  </footer>
);

export { Footer };
